
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CopyList from '../../components/copypage/CopyList.vue';
import CommonUtils from '@/utils/common-utils';
import MultiSelectField from '../../components/core/MultiSelectField.vue';
import { VForm } from 'vuetify/lib';
import CopyLessonList from '@/components/copypage/CopyLessonList.vue';
import CopyUnitList from '@/components/copypage/CopyUnitList.vue';
import StudentUtils from '@/utils/student-utils';
import Confirm from '../../components/core/Confirm.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import { sections } from '@/constants';
import { now } from 'lodash';

const settings = namespace('settings');
const classes = namespace('classes');
const classwork = namespace('classwork');
const copy = namespace('copy');
const students = namespace('students');
const lessonlists = namespace('lessonlists');
const lessons = namespace('lessons');
const units = namespace('units');
const plans = namespace('plans');

@Component({
  components: {
    CopyList,
    MultiSelectField,
    CopyLessonList,
    CopyUnitList
  }
})
export default class Copy extends Vue {
  copySide = 0;

  @lessonlists.Action
  getListLessons!: (params?: any) => Promise<any>;

  @lessonlists.Getter
  getLessonListClassLessons!: any[];

  @lessonlists.Getter
  getCopyListSharedClassLessons!: any[];

  @lessonlists.Action
  getClassesLessons!: (params?: any) => Promise<any>;

  @lessonlists.Getter('getSelectedLessonsInLessonList')
  selectedLessonListLessons!: any[];

  @lessonlists.Mutation
  resetLessonBankList!: () => void;

  @classes.Action
  getSharedClasses!: (params?: any) => Promise<any>;

  @classes.Action
  loadSchoolYearClasses!: (param: number) => Promise<any>;

  @classes.Getter
  getLessonBanks!: any;

  @classes.Getter
  getDistrictLessonBankItems!: any;

  @classes.Getter
  getClassesMap!: any;

  @classwork.Action
  getAssignments!: (params?: any) => Promise<any>;

  @classwork.Action
  getAssessments!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssessments!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssessmentsMany!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssessmentsToAll!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssessmentsAll!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssignments!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssignmentsMany!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssignmentsToAll!: (params?: any) => Promise<any>;

  @copy.Action
  copyAssignmentsAll!: (params?: any) => Promise<any>;

  @copy.Action
  copyClasses!: (params?: any) => Promise<any>;

  @copy.Action
  copyStudents!: (params?: any) => Promise<any>;

  @copy.Action('copyLesson')
  importLessons!: (param: any) => Promise<any>;

  @copy.Action('copyLessonMany')
  importLessonsMany!: (param: any) => Promise<any>;

  @lessons.Action
  linkTo!: (param: any) => Promise<any>;

  @copy.Action
  linkToMany!: (param: any) => Promise<any>;

  @students.Action
  loadStudents!: (params?: any) => Promise<any>;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @settings.Getter
  getEmailAddress!: any;

  @settings.Getter
  getUserId!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter
  getSchoolYears!: any;

  @settings.Getter
  getLessonSections!: any;

  @settings.Getter
  getSharingOptionsSettings!: any;

  @settings.Getter
  getCurrentSchoolYearId!: any;

  @settings.Action
  saveSharedTeacher!: (params?: any) => Promise<any>;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  @units.Action
  loadYearsUnits!: (params: any) => Promise<any>;

  @units.Mutation
  setHaveUnits!: (haveUnits: boolean) => void;

  @plans.Mutation
  resetPlans!: () => void;

  get hasText() {
    return CommonUtils.hasText;
  }

  copyFromTypeList = [
    { text: this.$t('assessmentsLabel'), code: 'AS', message: this.$t('assessmentCopyMessage') },
    { text: this.$t('assignmentsLabel'), code: 'AN', message: this.$t('assignmentCopyMessage') },
    { text: this.$t('classesLabel'), code: 'C', message: this.$t('classCopyMessage') },
    { text: this.$t('lessonsLabel'), code: 'L', message: this.$t('lessonCopyMessage') },
    { text: this.$t('studentsLabel'), code: 'S', message: this.$t('studentCopyMessage') },
    { text: this.$t('unitLabel'), code: 'U', message: this.$t('unitCopyMessage') }
  ]

  dialog = {
    addTeacher: false,
    importError: false
  }

  get fromAllClasses() {
    return (this.copyFromClass && this.copyFromClass.classId === 'All');
  }

  get toAllClasses() {
    return (this.copyToClass && this.copyToClass.cId === 'All');
  }

  get copyFromSelf() {
    return this.copyFromTeacher && this.copyFromTeacher.id === this.getUserId;
  }

  get showLinkLesson() {
    return this.copyFromType.code === 'L' && this.copyToClass && (this.copyFromSelf || (this.copyFromTeacher && this.copyFromTeacher.fromPermissions))
  }

  get appendDate() {
    if (this.copyToClass && this.copyToItems.length > 0) {
      const reverseItems = this.copyToItems.slice().reverse();
      for (const i in reverseItems) {
        if (reverseItems[i].lessonId) {
          if (+i === 0) {
            return '12/31/9999';
          } else {
            return reverseItems[+i - 1].lessonDate || reverseItems[+i - 1].date;
          }
        }
      }
      return (this.copyToItems[0].lessonDate || this.copyToItems[0].date);
    } else {
      return '';
    }
  }

  get localCopyFromYearList() {
    return this.copyFromYearList.map(yl => {
      return {
        ...yl,
        type: yl.parentDistrictId > 0 ? 'D' : yl.parentSchoolId > 0 ? 'S' : '',
        value: yl
      }
    })
  }

  initialLoad = true;

  // copy from selections
  copyFromType = this.copyFromTypeList[3];
  copyFromTeacher: any = null;
  copyFromYear: any = null;
  copyFromClass: any = null;
  copyFromBank: any = null;

  // copy from options
  copyFromTeacherList: Array<any> = [];
  copyFromYearList: Array<any> = [];
  copyFromClassList: Array<any> = [];
  copyFromBankList: Array<any> = [];

  get filteredCopyFromClassList() {
    if (this.copyFromClassList.length === 1 && this.copyFromClassList[0].classId === 'All') {
      return [];
    } else {
      return this.copyFromClassList;
    }
  }

  // copy from display
  showCopyFromTeacher = true;
  showCopyFromYear = true;
  showCopyFromClass = true;
  showCopyFromBank = true;
  isFromDistrict = false;
  get bankNotHidden() {
    return (!this.copyFromTeacher || this.copyFromTeacher.isDistrict || this.copyFromSelf || this.copyFromBankList.length > 0);
  }

  get isCopyingToLessonBank() {
    return (this.copyFromType.code === 'C' && this.copyToYearBank && this.copyToYearBank.yearId === 0)
  }

  // items
  copyFromItems: Array<any> = [];
  copyToItems: Array<any> = [];

  // errors
  copyFromError: any = null;
  copyToError = '';

  // copy to selections
  copyToClass: any = null;
  copyToYearBank: any = null;
  copyToBank: any = null;

  // copy to options
  copyToClassList: Array<any> = [];
  copyToYearBankList: Array<any> = [];
  copyToBankList: Array<any> = [];

  get filteredCopyToClassList() {
    return this.copyToClassList.filter((c) => {
      if (c.cId === 'All') {
        return (this.copyFromType.code !== 'U' && this.filteredCopyFromClassList.length > 0);
      }
      return true;
    });
  }

  // copy to display
  showCopyToClass = true;
  showCopyToYearBank = false;
  showCopyToBank = true;

  // extras for Class
  selectedClassItems: any = [];
  classItems = [
    { text: this.$t('daysLabelCapital'), value: 'importDays' },
    { text: this.$t('timesLabel'), value: 'importTimes' },
    { text: this.$t('lessonsLabel'), value: 'importLessons' },
    { text: this.$t('templatesLabel'), value: 'importTemplates' },
    { text: this.$t('assessmentsLabel'), value: 'importAssessments' },
    { text: this.$t('assignmentsLabel'), value: 'importAssignments' },
    { text: this.$t('unitsLabel'), value: 'importUnits' }
  ]

  // extras for Lesson
  allLessonItems: Array<any> = [];
  copyFromLessonUnits: any = [];
  copyFromLessonUnitsList: Array<any> = [];
  copyFromLessonStartDate = '';
  copyFromLessonEndDate = '';
  copyFromLessonStartPosition: any = '';
  copyFromLessonEndPosition: any = '';
  lessonCopyType = 'APPEND'
  lessonInsertDate = '';
  lessonInsertPosition: any = '';

  // extras for Units
  selectedYearUnits: Array<any> = [];
  selectedUnitItems: any = [];
  unitLessons: Array<any> = [];
  unitTabNames: any = {
    lesson: 'importUnitTab1',
    tab2: 'importUnitTab2',
    tab3: 'importUnitTab3',
    tab4: 'importUnitTab4',
    tab5: 'importUnitTab5',
    tab6: 'importUnitTab6',
    sharedStandards: 'importUnitStandards',
    myStandards: 'importUnitMyStandards',
    strategies: 'importUnitStrategies',
    schoolStandards: 'importUnitSchoolStandards'
  }

  get getUnitTabs() {
    const sect: any = sections.filter((s: any) => s !== 'attachments').map((s: string) => {
      const section = this.getLessonSections[s];
      return {
        value: this.unitTabNames[s],
        displayOrder: section[s + 'DisplayOrder'],
        enabled: section[s + 'Enabled'],
        text: section[s + 'Label']
      }
    }).filter((s: any) => s.enabled === 'Y').sort((a, b) => a.displayOrder - b.displayOrder);
    const sect2 = [
      { value: 'importUnitAssessments', text: this.$t('assessmentsLabel') },
      { value: 'importUnitAssignments', text: this.$t('assignmentsLabel') },
      { value: 'importUnitLessons', text: this.$t('unitLabel') + ' ' + this.$t('lessonsLabel') }];
    return sect.concat(sect2);
  }

  get isAllowedToCreateSchoolLessonBanks() {
    return this.$currentUser.isTeacher && this.userInfo.schoolSettings.primarySchool && this.userInfo.schoolSettings.primarySchool.allowCreateLessonBanks;
  }

  get getClassStart() {
    if (this.copyFromType.code === 'L' && this.copyFromClass && this.copyFromItems.length > 0) {
      const firstDate = this.copyFromItems[0].date;
      const lastDate = this.copyFromItems[this.copyFromItems.length - 1].date;
      if (DateTimeUtils.isBetween(DateTimeUtils.currentDateAddDays(0), firstDate, lastDate)) {
        return '';
      } else {
        return firstDate;
      }
    } else {
      return '';
    }
  }

  get getClassEnd() {
    if (this.copyFromType.code === 'L' && this.copyFromClass && this.copyFromItems.length > 0) {
      const firstDate = this.copyFromItems[0].date;
      const lastDate = this.copyFromItems[this.copyFromItems.length - 1].date;
      if (DateTimeUtils.isBetween(DateTimeUtils.currentDateAddDays(0), firstDate, lastDate)) {
        return '';
      } else {
        return lastDate;
      }
    } else {
      return '';
    }
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('closeLessonBankEditor', () => {
        this.initCopyTo();
      });
      this.$eventBus.$on('CSVuploaded', () => {
        this.loadCopyToItemsClass();
      });
      this.$eventBus.$on('closeClassEditorMain', (reload: boolean) => {
        this.initCopyTo();
      });
    });

    this.loadSharedTeachers();
    this.initCopyTo();
    this.copyFromTeacher = this.copyFromTeacherList[0];
    this.initialLoad = true;
  }

  destroyed() {
    this.$eventBus.$off('closeLessonBankEditor');
    this.$eventBus.$off('CSVuploaded');
    this.$eventBus.$off('closeClassEditor');
  }

  loadSharedTeachers() {
    const myClasses = [{ name: this.$t('myClassesLabel'), id: this.getUserId }];
    const sharedTeachers = this.getSharingOptionsSettings.sharedList.filter((s: any) => s.teacherSharedId !== this.getUserId).map((s: any) => {
      s.name = s.sharedUserEmail || s.sharedSchoolName;
      s.id = s.teacherSharedId || s.sharedSchoolId || s.sharedDistrictId;
      s.isDistrict = Boolean(s.sharedSchoolId || s.sharedDistrictId);
      s.bankOptions = (s.years.find((y: any) => y.yearId === 0) || {}).classes || [];
      return s;
    })
    const additionalOptions = [{ name: this.$t('importCSVLabel'), id: 1 }, { name: this.$t('addTeacherLabel'), id: 2 }];
    this.copyFromTeacherList = myClasses.concat(sharedTeachers).concat(additionalOptions);
  }

  initCopyTo() {
    this.copyToClassList = [];
    const classMap = this.getClassesMap;
    for (const i in classMap) {
      if (!classMap[i].collaborateType || classMap[i].collaborateType !== 4) {
        this.copyToClassList.push(classMap[i]);
      }
    }
    this.copyToClassList.sort((a, b) => {
      if (a.cN < b.cN) return -1;
      if (a.cN > b.cN) return 1;
      return 0;
    })
    this.copyToClassList = [{ cN: this.$t('addClassLabel'), cId: 0 }, { cN: this.$t('allClassesLabel'), cId: 'All' }].concat(this.copyToClassList);

    this.copyToBankList = [{ className: 'X1', classId: 0 }];
    if (this.isAllowedToCreateSchoolLessonBanks) {
      this.copyToBankList.push({ className: 'X2', classId: 1 });
    }
    this.copyToBankList = this.copyToBankList.concat(this.getLessonBanks.map((b: any) => {
      b.className = b.cN;
      b.classId = b.cId;
      b.bankSharedOrigin = false;
      return b;
    }))
    this.copyToBankList = this.copyToBankList.concat(this.getSharedBanks.filter((b: any) => b.hasFullPermissions));
    this.copyToYearBankList = [{ yearName: this.$t('lessonBankLabel'), yearId: 0 }].concat(this.getSchoolYears);
  }

  get getSharedBanks():any[] {
    const sharedClasses:any = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (sharedClass[1].yearId === 0) {
        sharedClass[1].bankSharedOrigin = true;
        sharedClasses.push(sharedClass[1]);
      }
    });
    return sharedClasses;
  }

  get getName() {
    return StudentUtils.getStudentLabel;
  }

  $refs!: {
    confirm: Confirm,
    copyFromList: CopyList,
    copyToList: CopyList,
    addTeacherForm: InstanceType<typeof VForm>,
    addLessonBankForm: InstanceType<typeof VForm>,
    copyFromLessonlist: CopyLessonList,
    copyToLessonList: CopyLessonList,
    copyFromUnitlist: CopyUnitList,
    dateObserver: any
  };

  // OPENING SUBPAGES & MODALS
  addLessonBank() {
    this.$eventBus.$emit('openSubPage', {
      type: 'lessonBanks',
      width: 600,
      modal: true,
      input: {
        action: 'LB',
        classId: 0,
        data: null,
        schoolOrDistrict: null
      }
    });
  }

  addSchoolLessonBank() {
    this.$eventBus.$emit('openSubPage', {
      type: 'lessonBanks',
      width: 500,
      modal: true,
      input: {
        action: 'SLB',
        classId: 0,
        data: null,
        schoolOrDistrict: 0
      }
    });
  }

  addClass() {
    this.$eventBus.$emit('openSubPage', {
      type: 'class',
      width: 500,
      modal: true,
      input: {
        action: 'A'
      }
    });
  }

  importCSV() {
    if (!this.copyToClass) {
      this.dialog.importError = true;
    } else {
      this.$eventBus.$emit('openSubPage', {
        type: 'csv',
        width: 600,
        modal: true,
        input: {
          type: this.$t('lessonBanksLabel'),
          CSVclassId: this.copyToClass.cId,
          importWhat: 'lessons'
        }
      });
    }
  }

  addTeacherEmail = '';
  addTeacherKey = '';
  addTeacherError = '';
  editingTeacher = false;
  addTeacher() {
    const form: any = this.$refs.addTeacherForm;
    if (form) form.resetValidation();
    this.addTeacherEmail = '';
    this.addTeacherKey = '';
    this.addTeacherError = '';
    this.dialog.addTeacher = true;
    this.editingTeacher = false;
  }

  editTeacher(item: any) {
    const form: any = this.$refs.addTeacherForm;
    if (form) form.resetValidation();
    this.addTeacherEmail = item.sharedUserEmail;
    this.addTeacherKey = item.sharedUserKey;
    this.addTeacherError = '';
    this.dialog.addTeacher = true;
    this.editingTeacher = true;
  }

  saveTeacher() {
    const form: any = this.$refs.addTeacherForm;
    if (form.validate()) {
      CommonUtils.showLoading();
      this.saveSharedTeacher({ sharedUserEmail: this.addTeacherEmail, sharedUserKey: this.addTeacherKey }).then((d: any) => {
        if (d.data.invalidTeacher) {
          this.addTeacherError = this.$t('importEventErrorMsg3').toString();
        } else {
          this.dialog.addTeacher = false;
          this.loadSharedTeachers();
        }
        CommonUtils.hideLoading();
      })
    }
  }

  deleteTeacher() {
    CommonUtils.showLoading();
    this.saveSharedTeacher({ sharedUserEmail: this.addTeacherEmail, sharedUserKey: this.addTeacherKey, action: 'D' }).then(() => {
      this.reloadSettings().then(() => {
        this.loadSharedTeachers();
        this.dialog.addTeacher = false;
        if (this.copyFromTeacher.sharedUserEmail === this.addTeacherEmail) {
          this.copyFromTeacher = null;
        }
        CommonUtils.hideLoading();
      })
    })
  }

  // COPY FROM OPTIONS
  @Watch('copyFromType')
  loadCopyFromDisplay() {
    this.copyFromItems = [];

    this.showCopyFromTeacher = ['AS', 'AN', 'C', 'L', 'U'].includes(this.copyFromType.code);
    this.showCopyFromYear = ['AS', 'AN', 'C', 'S', 'L', 'U'].includes(this.copyFromType.code);
    this.showCopyFromClass = ['AS', 'AN', 'L', 'U'].includes(this.copyFromType.code);
    this.showCopyFromBank = ['L', 'U'].includes(this.copyFromType.code);

    this.showCopyToClass = ['AS', 'AN', 'L', 'U'].includes(this.copyFromType.code);
    this.showCopyToYearBank = ['C', 'S'].includes(this.copyFromType.code);
    this.showCopyToBank = ['L', 'U'].includes(this.copyFromType.code);

    switch (this.copyFromType.code) {
      case 'AS': case 'AN': {
        if (this.copyFromClass) {
          this.loadClassItems();
        } else {
          this.loadYearItems();
        }
        break;
      }
      case 'C': {
        this.selectedClassItems = this.classItems.map((i: any) => { return i.value });
        this.copyFromClassList = [];
        this.copyFromClass = null;
        if (this.copyFromYear) {
          this.loadYearItems();
        }
        break;
      }
      case 'S': {
        if (this.copyFromTeacher.teacherId === this.copyFromTeacherList[0].teacherId && this.copyFromYear) {
          this.loadYearItems();
        } else {
          this.copyFromTeacher = this.copyFromTeacherList[0];
        }
        break;
      }
      case 'L': {
        this.copyFromLessonStartDate = '';
        this.copyFromLessonEndDate = '';
        this.copyFromLessonStartPosition = '';
        this.copyFromLessonEndPosition = '';
        this.lessonCopyType = 'APPEND';
        this.lessonInsertDate = '';

        this.updateLessonLogic();
        break;
      }
      case 'U': {
        this.selectedUnitItems = this.getUnitTabs.map((i: any) => { return i.value })
        this.updateLessonLogic();
        break;
      }
    }
  }

  updateLessonLogic() {
    if (this.copyFromBank) {
      this.loadBankItems();
    } else if (this.copyFromClass) {
      this.loadClassItems();
    } else {
      this.loadYearItems();
    }
  }

  @Watch('copyFromTeacher')
  loadCopyFromOptions() {
    if (!this.copyFromTeacher) {
      if (this.copyFromYear) {
        this.copyFromYear = null;
      }
      return;
    }
    this.copyFromError = null;
    this.isFromDistrict = false;
    this.copyFromYearList = [];
    this.copyFromClassList = [];
    this.copyFromBankList = [];
    this.copyFromClass = null;
    this.copyFromYear = null;
    this.copyFromBank = null;
    this.copyFromItems = [];
    if (this.copyFromSelf) {
      CommonUtils.showLoading();
      this.getSharedClasses({
        sharedUserEmail: this.getEmailAddress,
        sharedUserKey: this.getSharingOptionsSettings.sharedUserKey
      }).then((d: any) => {
        this.copyFromYearList = d.data.years;
        this.copyFromBankList = this.getLessonBanks.map((b: any) => {
          b.className = b.cN;
          b.classId = b.cId;
          return b;
        })
        this.copyFromBankList = this.copyFromBankList.concat(this.getSharedBanks);
        if (this.initialLoad) {
          this.copyFromYear = this.copyFromYearList.find((y) => y.yearId === this.getCurrentSchoolYearId);
          this.initialLoad = false;
        }
        CommonUtils.hideLoading();
      })
    } else {
      if (this.copyFromTeacher.isDistrict) {
        this.isFromDistrict = true;
        this.copyFromBankList = this.copyFromTeacher.years[0].classes;
        this.copyFromItems = [];
      } else if (!this.copyFromTeacher.fromPermissions) {
        this.getSharedClasses({
          sharedUserEmail: this.copyFromTeacher.sharedUserEmail,
          sharedUserKey: this.copyFromTeacher.sharedUserKey
        }).then((d: any) => {
          this.copyFromYearList = d.data.years;
          if (d.data.years.length === 0) {
            this.copyFromError = this.$t('importEventErrorMsg3').toString();
          }
          CommonUtils.hideLoading();
        })
      } else {
        this.copyFromBankList = this.copyFromTeacher.bankOptions;
        this.copyFromYearList = this.copyFromTeacher.years;
        if (CommonUtils.isEmpty(this.copyFromYearList)) {
          this.copyFromError = this.$t('importEventErrorMsg3').toString();
          this.copyFromYear = null;
          this.copyFromClass = null;
          this.copyFromItems = [];
        }
      }
    }
  }

  @Watch('copyFromBank')
  loadBankItems(isReload?: boolean) {
    if (!this.copyFromBank) {
      return;
    }
    CommonUtils.showLoading();
    this.copyFromYear = null;

    if (this.copyFromType.code === 'L') {
      this.copyFromLessonUnitsList = [{ title: this.$t('allUnitsLabel'), id: 0 }].concat(this.copyFromBank.units.map((u: any) => {
        u.title = (u.num || u.unitNum) + ' - ' + (u.title || u.unitTitle);
        return u;
      }));
      this.copyFromLessonUnits = this.copyFromLessonUnitsList[0];
      this.getListLessons({
        lessonView: 'from',
        userMode: 'F',
        classId: this.copyFromBank.cId || this.copyFromBank.classId,
        yearId: 0,
        email: this.getEmailAddress,
        key: this.getSharingOptionsSettings.sharedUserKey,
        startDate: '',
        endDate: '',
        allLessons: 'Y'
      }).then(() => {
        if (!isReload) {
          this.$refs.copyToLessonList.scrollTop();
        }
        this.allLessonItems = this.getCopyListSharedClassLessons;
        if (!isReload) {
          this.copyFromLessonStartPosition = '';
          this.copyFromLessonEndPosition = '';
          this.copyFromItems = this.getCopyListSharedClassLessons;
        } else {
          this.loadLessonPositionRange();
        }

        CommonUtils.hideLoading();
      })
    } else if (this.copyFromType.code === 'U') {
      if (this.copyFromSelf) {
        this.loadYearsUnits({
          yearId: 0,
          fetchLessons: true
        }).then((d: any) => {
          this.selectedYearUnits = d.data.units;
          this.copyFromItems = this.selectedYearUnits.filter((u: any) => u.subjectId === this.copyFromBank.cId || u.subjectId === this.copyFromBank.classId);
          this.getListLessons({
            lessonView: 'from',
            userMode: 'F',
            classId: (this.copyFromBank.cId || this.copyFromBank.classId),
            yearId: 0,
            email: this.getEmailAddress,
            key: this.getSharingOptionsSettings.sharedUserKey,
            startDate: '',
            endDate: '',
            allLessons: 'Y'
          }).then(() => {
            this.unitLessons = this.getCopyListSharedClassLessons;
            CommonUtils.hideLoading();
          })
        })
      } else {
        this.copyFromItems = this.copyFromBank.units;
        this.getListLessons({
          lessonView: 'from',
          userMode: 'F',
          classId: this.copyFromBank.classId,
          yearId: 0,
          email: this.getEmailAddress,
          key: this.getSharingOptionsSettings.sharedUserKey,
          startDate: '',
          endDate: '',
          allLessons: 'Y'
        }).then(() => {
          this.unitLessons = this.getCopyListSharedClassLessons;
          CommonUtils.hideLoading();
        })
      }
    }
  }

  @Watch('copyFromYear')
  loadYearItems() {
    if (!this.copyFromYear) {
      this.copyFromClassList = [];
      this.copyFromClass = null;
      return;
    }
    switch (this.copyFromType.code) {
      case 'AS': case 'AN': case 'L': case 'U': {
        if (this.copyFromBank) {
          this.copyFromBank = null;
          this.copyFromItems = [];
        }
        if (!this.copyFromClass) {
          this.copyFromItems = [];
        }
        if (this.copyFromYear) {
          this.copyFromClass = null;
          this.copyFromClassList = [{ className: this.$t('allClassesLabel'), classId: 'All' }].concat(this.copyFromYear.classes);
        }

        if (this.copyFromType.code === 'U') {
          if (this.copyFromSelf || this.copyFromTeacher.fromPermissions) {
            break;
          }
          let request = {};
          if (this.copyFromSelf) {
            request = {
              yearId: this.copyFromYear.yearId,
              email: this.getEmailAddress,
              key: this.getSharingOptionsSettings.sharedUserKey
            }
          } else {
            request = {
              yearId: this.copyFromYear.yearId,
              email: this.copyFromTeacher.sharedUserEmail,
              key: this.copyFromTeacher.sharedUserKey
            }
          }
          CommonUtils.showLoading();
          this.loadYearsUnits(request).then((d: any) => {
            this.selectedYearUnits = d.data.units;
            CommonUtils.hideLoading();
          })
        }
        break;
      }
      case 'C': {
        CommonUtils.showLoading();
        if (this.copyFromSelf) {
          this.loadSchoolYearClasses(this.copyFromYear.yearId).then((d: any) => {
            this.copyFromItems = d.data.classes;
            CommonUtils.hideLoading();
          })
        } else {
          this.copyFromItems = this.copyFromYear.classes.map((c: any) => {
            c.cId = c.classId;
            c.cN = c.className;
            c.cSd = c.startDate;
            c.cEd = c.endDate;
            return c;
          });
          CommonUtils.hideLoading();
        }
        break;
      }
      case 'S': {
        CommonUtils.showLoading();
        this.loadStudents({
          yearId: this.copyFromYear.yearId,
          email: this.getEmailAddress,
          key: this.getSharingOptionsSettings.sharedUserKey,
          userMode: 'T'
        }).then((d: any) => {
          this.copyFromItems = d.data.students.filter((s: any) => s.authorId === this.getUserId).map((s: any) => {
            s.name = this.getName(s);
            return s;
          });
          CommonUtils.hideLoading();
        })
        break;
      }
    }
  }

  reloadUnits(): Promise<any> {
    if (this.selectedYearUnits.length > 0 && this.selectedYearUnits[0].yearId === this.copyFromYear.yearId) {
      return Promise.resolve(true);
    } else if (this.copyFromTeacher && (this.copyFromSelf || this.copyFromTeacher.fromPermissions)) {
      let sharedUnits: any = [];
      for (const i in this.copyFromYear.classes) {
        sharedUnits = sharedUnits.concat(this.copyFromYear.classes[i].units || []);
      }
      this.selectedYearUnits = sharedUnits;
      return Promise.resolve(true);
    } else {
      let request = {};
      if (this.copyFromSelf) {
        request = {
          yearId: this.copyFromYear.yearId,
          email: this.getEmailAddress,
          key: this.getSharingOptionsSettings.sharedUserKey
        }
      } else {
        request = {
          yearId: this.copyFromYear.yearId,
          email: this.copyFromTeacher.sharedUserEmail,
          key: this.copyFromTeacher.sharedUserKey
        }
      }
      return this.loadYearsUnits(request).then((d: any) => {
        this.selectedYearUnits = d.data.units;
      })
    }
  }

  @Watch('copyFromClass')
  loadClassItems(preventScrollTop?: boolean) {
    if (!this.copyFromClass && !this.copyFromBank) {
      this.copyFromItems = [];
    }
    if ((!this.copyFromClass || this.copyFromClass.classId !== 'All') && this.copyToClass && this.copyToClass.cId === 'All') {
      this.copyToClass = null;
    }
    if (!this.copyFromClass) {
      return;
    }
    switch (this.copyFromType.code) {
      case 'AS': case 'AN': {
        CommonUtils.showLoading();
        let request: any = {};
        if (this.copyFromSelf) {
          request = {
            classId: (this.copyFromClass.classId === 'All') ? 0 : this.copyFromClass.classId,
            yearId: this.copyFromYear.yearId,
            email: this.getEmailAddress,
            key: this.getSharingOptionsSettings.sharedUserKey
          }
        } else {
          request = {
            classId: (this.copyFromClass.classId === 'All') ? 0 : this.copyFromClass.classId,
            yearId: this.copyFromYear.yearId,
            email: this.copyFromTeacher.sharedUserEmail,
            key: this.copyFromTeacher.sharedUserKey,
            fromPermissions: this.copyFromTeacher.fromPermissions,
            teacherSharedId: this.copyFromTeacher.teacherSharedId
          }
        }
        if (this.copyFromType.code === 'AS') {
          this.getAssessments(request).then((d: any) => {
            this.copyFromItems = d.resp.data.assessments.filter((a: any) => this.copyFromClass.classId === 'All' || a.subjectId === request.classId)
              .map((a: any) => {
                a.isClasswork = true;
                return a;
              });
            CommonUtils.hideLoading();
          })
        } else {
          this.getAssignments(request).then((d: any) => {
            this.copyFromItems = d.resp.data.assignments.filter((a: any) => this.copyFromClass.classId === 'All' || a.subjectId === request.classId)
              .map((a: any) => {
                a.isClasswork = true;
                return a;
              });
            CommonUtils.hideLoading();
          })
        }
        break;
      }
      case 'L': {
        let classUnits = [];
        if (this.copyFromClass.units) {
          classUnits = this.copyFromClass.units.map((u: any) => {
            return {
              title: u.unitNum + ' - ' + u.unitTitle,
              id: u.unitId
            }
          })
        }
        this.copyFromLessonUnitsList = [{ title: this.$t('allUnitsLabel'), id: 0 }].concat(classUnits);
        this.copyFromLessonUnits = this.copyFromLessonUnitsList[0];
        this.copyFromLessonStartDate = '';
        this.copyFromLessonEndDate = '';
        CommonUtils.showLoading();

        const request: any = {
          lessonView: 'from',
          userMode: 'F',
          classId: this.copyFromClass.classId,
          yearId: this.copyFromYear.yearId,
          key: '',
          startDate: '',
          endDate: '',
          allLessons: 'Y'
        }
        if (this.copyFromSelf) {
          request.email = this.getEmailAddress;
        } else {
          request.email = this.copyFromTeacher.sharedUserEmail;
          request.fromPermissions = this.copyFromTeacher.fromPermissions;
          if (!request.fromPermissions) {
            request.key = this.copyFromTeacher.sharedUserKey;
          }
        }
        Promise.resolve().then(() => {
          if (this.fromAllClasses) {
            request.classId = this.sharedClassIds
            return this.getClassesLessons(request);
          } else {
            return this.getListLessons(request);
          }
        }).then(() => {
          this.copyFromItems = this.getCopyListSharedClassLessons;
          if (this.copyFromItems.length > 0) {
            this.firstLesson = this.copyFromItems[0].date;
            this.lastLesson = this.copyFromItems[this.copyFromItems.length - 1].date;
          } else {
            this.firstLesson = null;
            this.lastLesson = null;
          }
          if (!preventScrollTop) {
            this.$refs.copyFromLessonlist.scrollTop();
          }
          this.allLessonItems = this.getCopyListSharedClassLessons;
          CommonUtils.hideLoading();
        })
        break;
      }
      case 'U': {
        CommonUtils.showLoading();
        this.reloadUnits().then(() => {
          this.copyFromItems = this.selectedYearUnits.filter((u: any) => this.copyFromClass.classId === 'All' || u.subjectId === this.copyFromClass.classId);
          Promise.resolve().then(() => {
            const request = {
              lessonView: 'from',
              userMode: 'F',
              classId: this.copyFromClass.classId,
              yearId: this.copyFromYear.yearId,
              email: (this.copyFromSelf) ? this.getEmailAddress : this.copyFromTeacher.sharedUserEmail,
              key: (this.copyFromSelf) ? this.getSharingOptionsSettings.sharedUserKey : this.copyFromTeacher.sharedUserKey,
              fromPermissions: this.copyFromTeacher.fromPermissions,
              allLessons: 'Y'
            }
            if (this.fromAllClasses) {
              request.classId = this.sharedClassIds
              return this.getClassesLessons(request);
            } else {
              return this.getListLessons(request)
            }
          }).then(() => {
            this.unitLessons = this.getCopyListSharedClassLessons;
            CommonUtils.hideLoading();
          })
        })
      }
    }
  }

  @Watch('copyFromLessonUnits')
  filterLessonsByUnit() {
    if (this.copyFromBank) {
      this.loadLessonPositionRange(true);
    }
    if (this.copyFromLessonUnits.id === 0) {
      this.$refs.copyFromLessonlist?.scrollTop();
    } else {
      this.copyFromItems = this.copyFromItems.filter((l: any) => l.unitId === this.copyFromLessonUnits.id);
      this.$refs.copyFromLessonlist?.scrollTop();
    }
  }

  // SPECIFIC LESSON OPTIONS
  async validateLessonRangeDate() {
    this.lessonRangeDateError = '';
    if (!this.copyFromClass) {
      this.lessonRangeDateError = this.$t('assignmentErrorMsg1') as string;
      return;
    }
    const dateFieldsValid = await this.$refs.dateObserver.validate();
    if (this.firstLesson !== null && DateTimeUtils.isThisDateBeforeThatDate(this.copyFromLessonEndDate, this.firstLesson)) {
      this.lessonRangeDateError = this.$t('timeRangeEarlyWarning') as string;
    } else if (this.lastLesson !== null && DateTimeUtils.isThisDateAfterThatDate(this.copyFromLessonStartDate, this.lastLesson)) {
      this.lessonRangeDateError = this.$t('timeRangeLateWarning') as string;
    } else if (!dateFieldsValid) {
      this.lessonRangeDateError = this.$t('timeRangeInvalid') as string;
    } else {
      this.lessonRangeDateError = '';
    }
  }

  lessonRangeDateError = '';
  firstLesson: any = null;
  lastLesson: any = null;

  loadLessonTimeRange() {
    this.validateLessonRangeDate();
    if (this.lessonRangeDateError.length > 0) {
      return;
    }
    CommonUtils.showLoading();
    const request = {
      lessonView: 'from',
      userMode: 'F',
      classId: this.copyFromClass.classId,
      yearId: this.copyFromYear.yearId,
      email: (this.copyFromSelf) ? this.getEmailAddress : this.copyFromTeacher.sharedUserEmail,
      key: (this.copyFromSelf) ? this.getSharingOptionsSettings.sharedUserKey : this.copyFromTeacher.sharedUserKey,
      fromPermissions: this.copyFromTeacher.fromPermissions,
      startDate: this.copyFromLessonStartDate,
      endDate: this.copyFromLessonEndDate,
      allLessons: 'N'
    }
    Promise.resolve().then(() => {
      if (this.fromAllClasses) {
        request.classId = this.sharedClassIds;
        return this.getClassesLessons(request);
      } else {
        return this.getListLessons(request)
      }
    }).then(() => {
      this.copyFromItems = this.getCopyListSharedClassLessons;
      this.$refs.copyFromLessonlist.scrollTop();
      this.allLessonItems = this.getCopyListSharedClassLessons;
      CommonUtils.hideLoading();
    })
  }

  copyStartPosError: any = [];
  copyEndPosError: any = [];

  @Watch('copyFromLessonStartPosition')
  @Watch('copyFromLessonEndPosition')
  validateCopyFromPos() {
    if (CommonUtils.hasNoText(this.copyFromLessonStartPosition)) {
      this.copyStartPosError = [];
    } else if (this.copyFromLessonStartPosition < 0 || this.copyFromLessonStartPosition % 1 !== 0) {
      this.copyStartPosError = [() => this.$t('importErrorMsg25')];
    } else if (this.copyFromLessonStartPosition > this.allLessonItems.length) {
      this.copyStartPosError = [() => this.$t('importErrorMsg25b', { num: this.allLessonItems.length })];
    } else {
      this.copyStartPosError = [];
    }

    if (CommonUtils.hasNoText(this.copyFromLessonEndPosition)) {
      this.copyEndPosError = [];
    } else if (this.copyFromLessonEndPosition < 0 || this.copyFromLessonEndPosition % 1 !== 0) {
      this.copyEndPosError = [() => this.$t('importErrorMsg25')];
    } else if (this.copyFromLessonEndPosition > this.allLessonItems.length) {
      this.copyEndPosError = [() => this.$t('importErrorMsg25b', { num: this.allLessonItems.length })];
    } else if (CommonUtils.hasText(this.copyFromLessonStartPosition) && this.copyStartPosError.length === 0 && +this.copyFromLessonEndPosition < +this.copyFromLessonStartPosition) {
      this.copyEndPosError = [() => this.$t('importErrorMsg25c')]
    } else {
      this.copyEndPosError = [];
    }
  }

  loadLessonPositionRange(fromUnit?: boolean) {
    if (this.copyStartPosError.length === 0 && this.copyEndPosError.length === 0) {
      const startIdx = (CommonUtils.hasText(this.copyFromLessonStartPosition)) ? (this.copyFromLessonStartPosition - 1) : 0;
      const endIdx = (CommonUtils.hasText(this.copyFromLessonEndPosition)) ? this.copyFromLessonEndPosition : this.allLessonItems.length;
      this.copyFromItems = this.allLessonItems.slice(startIdx, endIdx);
      if (!fromUnit) {
        this.filterLessonsByUnit();
      }
      this.$refs.copyFromLessonlist.scrollTop();
    }
  }

  // COPY TO OPTIONS
  @Watch('copyFromType')
  reloadCopyTo() {
    switch (this.copyFromType.code) {
      case 'AS': case 'AN': return this.loadCopyToItemsClass();
      case 'L': return this.loadCopyToLessons();
      case 'C': case 'S': return this.loadCopyToItemsYear();
    }
  }

  @Watch('copyToClass')
  loadCopyToItemsClass() {
    if (!this.copyToClass) {
      this.copyToItems = [];
      return;
    }
    this.copyToBank = null;
    if (this.copyToClass.cId === 'All') {
      if (this.copyFromBank) {
        this.copyFromBank = null;
      }
      if (this.copyFromYear && this.filteredCopyFromClassList.length > 0) {
        this.copyFromClass = this.copyFromClassList[0];
      }
    }
    if (this.copyFromType.code === 'AS') {
      this.getAssessments({ classId: (this.copyToClass.cId === 'All') ? 0 : this.copyToClass.cId }).then((d: any) => {
        this.copyToItems = d.resp.data.assessments.filter((a: any) => this.copyToClass.cId === 'All' || a.subjectId === this.copyToClass.cId).map((a: any) => {
          a.assignmentId = a.assignmentId || a.sourceId;
          return a;
        });
        CommonUtils.hideLoading();
      })
    } else if (this.copyFromType.code === 'AN') {
      this.getAssignments({ classId: (this.copyToClass.cId === 'All') ? 0 : this.copyToClass.cId }).then((d: any) => {
        this.copyToItems = d.resp.data.assignments.filter((a: any) => this.copyToClass.cId === 'All' || a.subjectId === this.copyToClass.cId).map((a: any) => {
          a.assignmentId = a.assignmentId || a.sourceId;
          return a;
        });
        CommonUtils.hideLoading();
      })
    } else if (['L', 'U'].includes(this.copyFromType.code)) {
      this.loadCopyToLessons();
    }
  }

  @Watch('copyToBank')
  loadCopyToLessons(preventScrollTop?: boolean) {
    if (!this.copyToBank && !this.copyToClass) {
      this.copyToItems = [];
      return;
    }
    CommonUtils.showLoading();
    const request: any = {
      lessonView: 'list',
      userMode: 'T'
    }
    if (this.copyToBank) {
      this.copyToClass = null;
      request.classId = (this.copyToBank.classId || this.copyToBank.cId);
      request.email = this.getEmailAddress;
    } else {
      request.teacherId = this.getUserId;
      request.classId = this.copyToClass.cId;
    }
    Promise.resolve().then(() => {
      if (this.toAllClasses) {
        request.classId = this.classIds
        request.yearId = this.getCurrentSchoolYearId
        return this.getClassesLessons(request);
      } else {
        return this.getListLessons(request);
      }
    }).then(() => {
      this.copyToItems = this.getLessonListClassLessons;
      if (!preventScrollTop) {
        this.$refs.copyToLessonList.scrollTop();
      }
      CommonUtils.hideLoading();
    })
  }

  @Watch('copyToYearBank')
  loadCopyToItemsYear() {
    if (!this.copyToYearBank) {
      this.copyToItems = [];
      return;
    }
    if (this.copyFromType.code === 'C') {
      CommonUtils.showLoading();
      if (this.copyToYearBank.yearId) {
        this.loadSchoolYearClasses(this.copyToYearBank.yearId).then((d: any) => {
          this.copyToItems = d.data.classes;
          CommonUtils.hideLoading();
        })
      } else {
        this.loadSchoolYearClasses(this.getCurrentSchoolYearId).then((d: any) => {
          this.copyToItems = d.data.lessonBanks.concat(d.data.districtLessonBanks);
          CommonUtils.hideLoading();
        })
      }
    } else if (this.copyFromType.code === 'S') {
      CommonUtils.showLoading();
      this.loadStudents({
        yearId: this.copyToYearBank.yearId,
        userMode: 'T'
      }).then((d: any) => {
        this.copyToItems = d.data.students.filter((s: any) => s.authorId === this.getUserId).map((s: any) => {
          s.name = this.getName(s);
          return s;
        });
        CommonUtils.hideLoading();
      })
    }
  }

  // DRAG COPY LESSONS
  confirmLessonDragEvent(from: any, to: any) {
    if (from.lessonId === 0) {
      this.clearDragCopy();
      return;
    }
    const options: any = {
      title: this.$t('shareLessonLabel'),
      text: this.$t('shareLessonActions'),
      option1ButtonAlternativeText: this.$t('copyLabel'),
      option2ButtonAlternativeText: this.$t('linkLabel')
    }
    if (this.copyFromBank) {
      options.option3ButtonAlternativeText = this.$t('moveLabel')
    }
    this.$refs.confirm.confirm(options).then(result => {
      if (result === 1) {
        return this.copyLesson(false, from, to);
      } else if (result === 2) {
        return this.linkLesson(from, to);
      } else if (result === 3) {
        return this.copyLesson(true, from, to);
      } else {
        this.clearDragCopy();
      }
    })
  }

  copyLesson(move: boolean, fromLesson: any, toLesson: any) {
    const insertDate = toLesson.date
    const copyToClass = (this.copyToBank) ? (this.copyToBank.classId || this.copyToBank.cId) : toLesson.classId
    const importPosition = (this.copyFromBank) ? fromLesson.lessonNum : 0;

    const request: any = {
      startDate: fromLesson.date,
      endDate: fromLesson.date,
      insertDate: insertDate,
      insertExtraLesson: toLesson.extraLesson,
      insertToPos: 0,
      importStartPos: importPosition,
      importEndPos: importPosition,
      sharedClassId: fromLesson.classId,
      classId: copyToClass,
      importDrag: true,
      confirmBump: true
    }

    if (move) {
      request.move = 'Y';
    }

    CommonUtils.showLoading();
    this.bumpRequestWrapper(request, 'import');
  }

  linkLesson(fromLesson: any, toLesson: any) {
    const request: any = {
      targetExtraLesson: '0',
      targetStartDate: toLesson.date,
      targetSubjectId: toLesson.classId,
      verifyLinkAction: true,
      lessonId: [fromLesson.lessonId]
    }
    this.linkTo(request).then((d: any) => {
      this.verifyLink(request, d);
    });
  }

  clearDragCopy() {
    this.$refs.copyToLessonList.clearHighlight();
  }

  // DRAG COPY UNIT
  confirmUnitDragEvent(from: any, to: any) {
    if (this.copyToBank) {
      this.doCopyUnitDrag(from, to);
      return;
    }
    this.$refs.confirm.confirm({
      title: this.$t('shareLessonLabel'),
      text: this.$t('shareLessonActions'),
      option1ButtonAlternativeText: this.$t('copyLabel'),
      option2ButtonAlternativeText: this.$t('linkLabel')
    }).then(result => {
      if (result === 1) {
        return this.doCopyUnitDrag(from, to);
      } else if (result === 2) {
        return this.doLinkUnitDrag(from, to);
      } else {
        this.clearDragCopy();
      }
    })
  }

  doCopyUnitDrag(from: any, to: any) {
    CommonUtils.showLoading();
    const request: any = {
      sharedClassId: from.subjectId,
      sharedUnitId: from.unitId,
      classId: to.classId,
      insertDate: to.date,
      withCopySettings: true
    }
    for (const i in this.selectedUnitItems) {
      request[this.selectedUnitItems[i]] = true;
    }
    this.bumpRequestWrapper(request, 'import');
  }

  doLinkUnitDrag(from: any, to: any) {
    CommonUtils.showLoading();
    const request = {
      linkAction: 0,
      subjectId: from.subjectId,
      targetExtraLesson: '0',
      targetStartDate: to.date,
      targetSubjectId: to.classId,
      unitId: from.subjectId,
      verifyLinkAction: true,
      lessonId: from.lessonList.map((l: any) => { return l.lessonId })
    }
    this.linkTo(request).then((resp: any) => {
      this.verifyLink(request, resp);
    });
  }

  // VALIDATE COPY ON BUTTON PRESS
  startCopy() {
    this.copyToError = '';
    switch (this.copyFromType.code) {
      case 'AS': return this.validateCopyAssessments();
      case 'AN': return this.validateCopyAssignments();
      case 'C': return this.validateCopyClasses();
      case 'L': return this.validateCopyLesson(false);
      case 'S': return this.validateCopyStudents();
      case 'U': return this.validateCopyUnits(false);
    }
  }

  private generateCopyClassErrors() {
    if (!this.copyFromClass) {
      this.copyToError = this.$t('importErrorMsg21').toString();
      return true;
    } else if (!this.copyToClass) {
      this.copyToError = this.$t('errorMessage32').toString();
      return true;
    }
    return false;
  }

  private generateCopyYearErrors() {
    if (!this.copyFromYear) {
      this.copyToError = this.$t('importErrorMsg227').toString();
      return true;
    } else if (!this.copyToYearBank) {
      this.copyToError = this.$t('importErrorMsg228').toString();
      return true;
    }
    return false;
  }

  insertDateError: any = [];

  @Watch('lessonInsertDate')
  validateInsertDate() {
    if (!this.lessonInsertDate) {
      this.insertDateError = [() => 'requried'];
    } else if (this.copyToClass && !this.toAllClasses && !DateTimeUtils.isBetween(this.lessonInsertDate, this.copyToClass.cSd, this.copyToClass.cEd)) {
      this.insertDateError = [() => this.$t('importErrorMsg212').toString()];
    } else {
      this.insertDateError = [];
    }
    return this.generateInsertErrors();
  }

  private generateInsertErrors() {
    if (this.lessonCopyType !== 'INSERT') {
      return false;
    }
    if (CommonUtils.hasNoText(this.copyToClass && this.lessonInsertDate)) {
      this.copyToError = this.$t('importErrorMsg210').toString();
      return true;
    } else if (this.copyToClass && !this.toAllClasses && !DateTimeUtils.isBetween(this.lessonInsertDate, this.copyToClass.cSd, this.copyToClass.cEd)) {
      this.copyToError = this.$t('importErrorMsg212').toString();
    } else if (this.copyToBank && (this.lessonInsertPosition < 0 || CommonUtils.hasNoText(this.lessonInsertPosition))) {
      this.copyToError = this.$t('importErrorMsg29').toString();
      return true;
    } else {
      this.copyToError = '';
    }
    return false;
  }

  private checkPossibleDuplicates(group1: Array<any>, group2: Array<any>, attribute: string) {
    const group1Ids = group1.map((a: any) => { return a[attribute] });
    const group2Ids = group2.map((a: any) => { return a[attribute] });
    for (const i in group1Ids) {
      if (group2Ids.includes(group1Ids[i])) {
        return true;
      }
    }
    return false;
  }

  showCopyManyWarning(link?: boolean) {
    return this.$refs.confirm.confirm({
      title: link ? this.$t('linkAllLabel') : this.$t('copyAllLabel'),
      text: link ? this.$t('linkToAllWarning') : this.$t('copyToAllWarning'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    })
  }

  showCopyManyOptions() {
    return this.$refs.confirm.confirm({
      title: this.$t('copyAllLabel'),
      text: this.$t('copyToAllOptions'),
      option1ButtonAlternativeText: this.$t('toAllClasses'),
      option2ButtonAlternativeText: this.$t('toMatchingClasses')
    })
  }

  validateCopyAssessments() {
    if (this.generateCopyClassErrors()) {
    } else if (this.$refs.copyFromList.getSelectedItems().length === 0) {
      this.copyToError = this.$t('importErrorMsg226').toString();
    } else if (this.fromAllClasses && this.toAllClasses) {
      this.showCopyManyOptions().then((result: any) => {
        if (result === 1 || result === 2) {
          this.doCopyAssessments(result);
        }
      });
    } else if (this.checkPossibleDuplicates(this.$refs.copyFromList.getSelectedItems(), this.copyToItems, 'assessmentTitle')) {
      this.$refs.confirm.confirm({
        title: this.$t('copyAssessmentsLabel'),
        text: this.$t('confirmCopyAssessment'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result: any) => {
        if (result === 1) {
          this.doCopyAssessments();
        }
      });
    } else {
      this.doCopyAssessments();
    }
  }

  validateCopyAssignments() {
    if (this.generateCopyClassErrors()) {
    } else if (this.$refs.copyFromList.getSelectedItems().length === 0) {
      this.copyToError = this.$t('importErrorMsg223').toString();
    } else if (this.fromAllClasses && this.toAllClasses) {
      this.showCopyManyOptions().then((result: any) => {
        if (result === 1 || result === 2) {
          this.doCopyAssignments(result);
        }
      });
    } else if (this.checkPossibleDuplicates(this.$refs.copyFromList.getSelectedItems(), this.copyToItems, 'assignmentTitle')) {
      this.$refs.confirm.confirm({
        title: this.$t('copyAssignmentsLabel'),
        text: this.$t('confirmCopyAssignment'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result: any) => {
        if (result === 1) {
          this.doCopyAssignments();
        }
      });
    } else {
      this.doCopyAssignments();
    }
  }

  validateCopyClasses() {
    if (this.generateCopyYearErrors()) {
    } else if (this.copyToYearBank.yearId !== 0 && this.copyFromYear.classCycle !== this.copyToYearBank.classCycle) {
      this.copyToError = this.$t('importErrorMsg230').toString();
    } else if (this.$refs.copyFromList.getSelectedItems().length === 0) {
      this.copyToError = this.$t('importErrorMsg229').toString();
    } else {
      if (this.checkPossibleDuplicates(this.$refs.copyFromList.getSelectedItems(), this.copyToItems, 'cN')) {
        this.$refs.confirm.confirm({
          title: this.$t('copyClassLabel'),
          text: this.$t('confirmCopyClass'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result: any) => {
          if (result === 1) {
            this.doCopyClasses();
          }
        });
      } else {
        this.doCopyClasses();
      }
    }
  }

  validateCopyLesson(link: boolean) {
    this.copyToError = '';
    if (!this.copyFromClass && !this.copyFromBank) {
      this.copyToError = this.$t('importErrorMsg21').toString();
    } else if (!this.copyToClass && !this.copyToBank) {
      this.copyToError = this.$t('errorMessage32').toString();
    } else if (!this.copyFromBank && this.lessonRangeDateError !== '') {
      return false;
    } else if (this.validateInsertDate()) {
      return false;
    } else if (this.copyFromBank && (this.copyEndPosError.length > 0 || this.copyStartPosError.length > 0)) {
      this.copyToError = this.$t('importErrorMsg25').toString();
    } else if (link) {
      this.doLinkLessons();
    } else {
      this.doCopyLessons();
    }
  }

  validateCopyStudents() {
    if (this.generateCopyYearErrors()) {
    } else if (this.$refs.copyFromList.getSelectedItems().length === 0) {
      this.copyToError = this.$t('importErrorMsg233').toString();
    } else {
      if (this.checkPossibleDuplicates(this.$refs.copyFromList.getSelectedItems(), this.copyToItems, 'studentId')) {
        this.$refs.confirm.confirm({
          title: this.$t('copyStudentsLabel'),
          text: this.$t('confirmCopyStudent'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result: any) => {
          if (result === 1) {
            this.doCopyStudents();
          }
        });
      } else {
        this.doCopyStudents();
      }
    }
  }

  validateCopyUnits(link: boolean) {
    this.copyToError = '';
    if (!this.copyFromClass && !this.copyFromBank) {
      this.copyToError = this.$t('importErrorMsg21').toString();
    } else if (!this.copyToClass && !this.copyToBank) {
      this.copyToError = this.$t('errorMessage32').toString();
    } else if (!this.$refs.copyFromUnitlist.rowSelected()) {
      this.copyToError = this.$t('importErrorMsg214').toString();
    } else if (this.validateInsertDate()) {
      return false;
    } else if (link) {
      this.doLinkUnit();
    } else {
      this.doCopyUnit();
    }
  }

  // SEND COPY REQUEST
  doCopyAssessments(res?: any) {
    CommonUtils.showLoading();
    Promise.resolve().then(() => {
      if (this.fromAllClasses && this.toAllClasses) {
        if (res === 2) {
          return this.copyAssessmentsMany({
            classId: this.classIds,
            sharedClassId: this.sharedClassIds,
            assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
          });
        } else {
          return this.copyAssessmentsToAll({
            classId: this.classIds,
            sharedClassId: this.sharedClassIds,
            assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
          });
        }
      } else if (this.fromAllClasses) {
        return this.copyAssessmentsAll({
          classId: this.copyToClass.cId,
          sharedClassId: this.sharedClassIds,
          assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
        });
      } else {
        return this.copyAssessments({
          classId: this.copyToClass.cId,
          sharedClassId: this.copyFromClass.classId,
          assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
        })
      }
    }).then(() => {
      this.loadClassItems();
      this.loadCopyToItemsClass();
      CommonUtils.hideLoading();
    })
  }

  doCopyAssignments(res?: any) {
    CommonUtils.showLoading();
    Promise.resolve().then(() => {
      if (this.fromAllClasses && this.toAllClasses) {
        if (res === 2) {
          return this.copyAssignmentsMany({
            classId: this.classIds,
            sharedClassId: this.sharedClassIds,
            assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
          });
        } else {
          return this.copyAssignmentsToAll({
            classId: this.classIds,
            sharedClassId: this.sharedClassIds,
            assessmentIds: this.$refs.copyFromList.getSelectedItemsIds()
          });
        }
      } else if (this.fromAllClasses) {
        return this.copyAssignmentsAll({
          classId: this.copyToClass.cId,
          sharedClassId: this.sharedClassIds,
          assignmentIds: this.$refs.copyFromList.getSelectedItemsIds()
        });
      } else {
        return this.copyAssignments({
          classId: this.copyToClass.cId,
          sharedClassId: this.copyFromClass.classId,
          assignmentIds: this.$refs.copyFromList.getSelectedItemsIds()
        })
      }
    }).then(() => {
      this.loadClassItems();
      this.loadCopyToItemsClass();
      CommonUtils.hideLoading();
    })
  }

  doCopyClasses() {
    CommonUtils.showLoading();
    this.copyClasses({
      yearId: this.copyToYearBank.yearId,
      sharedYearId: this.copyFromYear.yearId,
      subjectIds: this.$refs.copyFromList.getSelectedItemsIds(),
      items: this.selectedClassItems
    }).then(() => {
      this.loadYearItems();
      this.loadCopyToItemsYear();
      this.resetPlans();
      CommonUtils.hideLoading();
    })
  }

  doCopyLessons() {
    if (this.fromAllClasses && this.toAllClasses) {
      this.showCopyManyWarning().then((result: any) => {
        if (result === 1) {
          CommonUtils.showLoading();
          this.doCopyLessonsMany();
        }
      });
    } else if (this.fromAllClasses) {
      CommonUtils.showLoading();
      this.doCopyLessonsMany();
    } else {
      CommonUtils.showLoading();
      const request: any = {
        insertDate: '',
        allTab1: 'N',
        sharedClassId: (this.copyFromClass) ? this.copyFromClass.classId : this.copyFromBank.cId || this.copyFromBank.classId,
        classId: (this.copyToClass) ? this.copyToClass.cId : (this.copyToBank.classId || this.copyToBank.cId),
        lessonUnitId: this.copyFromLessonUnits.id,
        confirmBump: true,
        mergeLinkedLessons: this.copyToBank !== null
      }
      if (this.copyFromClass) {
        request.startDate = this.copyFromLessonStartDate;
        request.endDate = this.copyFromLessonEndDate;
      } else {
        request.importStartPos = parseInt(this.copyFromLessonStartPosition) ? parseInt(this.copyFromLessonStartPosition) : 0;
        request.importEndPos = parseInt(this.copyFromLessonEndPosition) ? parseInt(this.copyFromLessonEndPosition) : 0;
        this.resetLessonBankList();
      }

      if (this.lessonCopyType === 'INSERT') {
        if (this.copyToClass) {
          request.insertDate = this.lessonInsertDate;
        } else {
          request.insertToPos = this.lessonInsertPosition;
        }
      }

      this.bumpRequestWrapper(request, 'import');
    }
  }

  get sharedClassIds() {
    return this.copyFromClassList.filter((c) => c.classId !== 'All').map((c) => { return c.classId });
  }

  get classIds() {
    return this.copyToClassList.filter((c) => c.cId !== 'All').map((c) => { return c.cId });
  }

  get matchingSubjectList() {
    const copyFromClasses = this.copyFromClassList.filter((c) => c.classId !== 'All');
    const copyToClasses = this.copyToClassList.filter((c) => c.cId !== 'All');

    const pairs = [];
    for (const j in copyToClasses) {
      const matches = [];
      for (const i in copyFromClasses) {
        if (copyFromClasses[i].className === copyToClasses[j].cN) {
          matches.push({ from: copyFromClasses[i].classId, to: copyToClasses[j].cId });
        }
      }
      if (matches.length === 1) {
        pairs.push(matches[0])
      } else if (matches.length > 1) {
        const completeMatch = matches.find((m) => m.from === m.to);
        if (completeMatch) {
          pairs.push(completeMatch)
        } else {
          pairs.push(matches[0])
        }
      }
    }
    return pairs;
  }

  get matchingSubjectListDated() {
    return this.matchingSubjectList.map((p: any) => {
      p.targetInsertDate = this.getAfterExistingLessonsDate(p.to);
      return p;
    })
  }

  doCopyLessonsMany() {
    const request: any = {
      insertDate: '',
      allTab1: 'N',
      lessonUnitId: this.copyFromLessonUnits.id,
      confirmBump: true,
      mergeLinkedLessons: this.copyToBank !== null
    }
    if (this.copyFromClass) {
      request.startDate = this.copyFromLessonStartDate;
      request.endDate = this.copyFromLessonEndDate;
    } else {
      request.importStartPos = parseInt(this.copyFromLessonStartPosition) ? parseInt(this.copyFromLessonStartPosition) : 0;
      request.importEndPos = parseInt(this.copyFromLessonEndPosition) ? parseInt(this.copyFromLessonEndPosition) : 0;
    }

    if (this.copyToClass) {
      request.copyList = this.matchingSubjectList;
    } else {
      request.copyList = this.copyFromClassList.filter((c) => c.classId !== 'All').map((c) => {
        return { from: c.classId, to: this.copyToBank.cId || this.copyToBank.classId }
      });
    }

    if (this.lessonCopyType === 'INSERT') {
      if (this.copyToClass) {
        request.insertDate = this.lessonInsertDate;
      } else {
        request.insertToPos = this.lessonInsertPosition;
      }
    }
    this.importLessonsMany(request).then((d: any) => {
      if (d.data.count > 0) {
        this.$refs.confirm.confirm({
          title: this.$t('copyLessonsLabel'),
          text: this.$t('bumpLessonsMessage', { count: d.data.count, startDate: d.data.lessonDates[0], endDate: d.data.lessonDates[d.data.count - 1] }),
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result: any) => {
          if (result === 1) {
            request.confirmBump = false;
            request.copyList = d.data.confirmRequests;
            this.importLessonsMany(request).then(() => {
              this.reloadLessons();
            })
          } else {
            CommonUtils.hideLoading();
          }
        });
      } else {
        this.reloadLessons();
      }
    })
  }

  doCopyStudents() {
    CommonUtils.showLoading();
    this.copyStudents({
      yearId: this.copyToYearBank.yearId,
      sharedYearId: this.copyFromYear.yearId,
      studentIds: this.$refs.copyFromList.getSelectedItemsIds()
    }).then(() => {
      this.loadYearItems();
      this.loadCopyToItemsYear();
      CommonUtils.hideLoading();
    })
  }

  doCopyUnit() {
    CommonUtils.showLoading();
    const request: any = {
      insertDate: '',
      sharedUnitId: this.$refs.copyFromUnitlist.getSelectedUnitId(),
      sharedClassId: (this.copyFromClass) ? this.$refs.copyFromUnitlist.getSelectedUnit().subjectId : (this.copyFromBank.cId || this.copyFromBank.classId),
      classId: (this.copyToClass) ? this.copyToClass.cId : (this.copyToBank.classId || this.copyToBank.cId),
      withCopySettings: true
    }
    if (this.lessonCopyType === 'INSERT') {
      if (this.copyToClass) {
        request.insertDate = this.lessonInsertDate;
      } else {
        request.insertToPos = this.lessonInsertPosition
      }
    }
    for (const i in this.selectedUnitItems) {
      request[this.selectedUnitItems[i]] = true;
    }
    this.bumpRequestWrapper(request, 'import');
    this.setHaveUnits(false);
  }

  reloadLessons() {
    this.clearDragCopy();
    CommonUtils.showLoading();
    if (this.copyFromType.code === 'L' && this.copyFromBank) {
      this.loadBankItems(true);
    }
    this.loadCopyToLessons(true);
    this.resetPlans();
    CommonUtils.hideLoading();
  }

  // BUMP MANY
  bumpRequestWrapperMany(request: any, type: string) {
    if (type === 'link') {
      this.linkToMany(request).then((d: any) => {
        if (d.data.deletedLessons) {
          this.confirmBumpLessonsMany(request, 'link', d.data.count);
        } else {
          this.reloadLessons();
        }
      });
    } else {
      this.importLessonsMany(request).then((d: any) => {
        if (d.resp.data.count) {
          this.confirmBumpLessonsMany(request, 'import', d.resp.data.count);
        } else {
          this.reloadLessons();
        }
      })
    }
  }

  // CONFIRM BUMP MANY
  confirmBumpLessonsMany(request: any, type: string, count: number) {
    this.$refs.confirm.confirm({
      title: this.$t('copyLessonsLabel'),
      text: this.$t('bumbManyLessonMessage', { count: count }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result: any) => {
      if (result === 1) {
        CommonUtils.showLoading();
        if (type === 'import') {
          request.confirmBump = false;
          this.importLessonsMany(request).then(() => {
            this.reloadLessons();
          })
        } else if (type === 'link') {
          request.verifyLinkAction = false;
          this.linkToMany(request).then(() => {
            this.reloadLessons();
          })
        }
      }
    });
  }

  // BUMP
  bumpRequestWrapper(request: any, type: string) {
    if (type === 'link') {
      this.linkTo(request).then((d: any) => {
        if (d.data.deletedLessons) {
          this.confirmBumpLessons(request, 'link', d.data.deletedLessons.length, d.data.deletedLessons.map((d: any) => { return d.date }));
        } else {
          this.reloadLessons();
        }
      });
    } else {
      this.importLessons(request).then((d: any) => {
        if (d.resp.data.count) {
          this.confirmBumpLessons(request, 'import', d.resp.data.count, d.resp.data.lessonDates);
        } else {
          this.reloadLessons();
        }
      })
    }
  }

  confirmBumpLessons(request: any, type: string, count: number, dates: Array<string>) {
    let text = '';
    if (count > 1) {
      text = this.$t('bumpLessonsMessage', { count: count, startDate: dates[0], endDate: dates[dates.length - 1] }).toString();
    } else {
      text = this.$t('bumpLessonMessage', { date: dates[0] }).toString();
    }

    this.$refs.confirm.confirm({
      title: this.$t('copyLessonsLabel'),
      text: text,
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result: any) => {
      if (result === 1) {
        CommonUtils.showLoading();
        if (type === 'import') {
          request.confirmBump = false;
          this.importLessons(request).then(() => {
            this.reloadLessons();
          })
        } else if (type === 'link') {
          request.verifyLinkAction = false;
          this.linkTo(request).then(() => {
            this.reloadLessons();
          })
        }
      } else {
        CommonUtils.hideLoading();
      }
    });
  }

  // LINK
  private getAfterExistingLessonsDate(classId?: number) {
    if (this.lessonCopyType === 'APPEND') {
      const reverseItems = this.copyToItems.slice().reverse();
      for (const i in reverseItems) {
        if (classId) {
          if (reverseItems[i].lessonId && (!classId || reverseItems[i].classId === classId)) {
            if (+i < this.copyFromClassList.length - 1) {
              return '12/31/9999';
            } else {
              return reverseItems[+i + 1 - this.filteredCopyFromClassList.length].lessonDate || reverseItems[+i + 1 - this.filteredCopyFromClassList.length].date;
            }
          }
        } else {
          if (reverseItems[i].lessonId) {
            if (+i === 0) {
              return '12/31/9999';
            } else {
              return reverseItems[+i - 1].lessonDate || reverseItems[+i - 1].date;
            }
          }
        }
      }
      return (this.copyToItems[0].lessonDate || this.copyToItems[0].date);
    } else {
      return this.lessonInsertDate;
    }
  }

  doLinkLessons() {
    if (this.fromAllClasses && this.toAllClasses) {
      this.showCopyManyWarning(true).then((result: any) => {
        if (result === 1) {
          CommonUtils.showLoading();
          const request: any = {
            targetExtraLesson: '0',
            targetSubjectId: this.copyToClass.cId,
            verifyLinkAction: true,
            lessons: this.copyFromItems,
            copyList: this.matchingSubjectListDated
          }
          this.linkToMany(request).then((resp: any) => {
            CommonUtils.hideLoading();
            this.verifyLinkMany(request, resp);
          });
        }
      });
    } else {
      CommonUtils.showLoading();
      let lessonIds;
      if (this.copyFromBank) {
        lessonIds = this.copyFromItems.filter((l) => {
          if ((this.copyFromLessonStartPosition && l.lessonNum < this.copyFromLessonStartPosition) || (this.copyFromLessonEndPosition && l.lessonNum > this.copyFromLessonEndPosition)) {
            return false;
          }
          return true;
        }).map((l: any) => { return l.lessonId || 0 });
      } else {
        lessonIds = this.copyFromItems.filter((l) => {
          if (this.copyFromLessonStartDate && this.copyFromLessonEndDate) {
            return DateTimeUtils.isBetween(l.date, this.copyFromLessonStartDate, this.copyFromLessonEndDate)
          } else if (this.copyFromLessonStartDate) {
            return DateTimeUtils.isThisDateEqualToThatDate(l.date, this.copyFromLessonStartDate) || DateTimeUtils.isThisDateAfterThatDate(l.date, this.copyFromLessonStartDate);
          } else if (this.copyFromLessonEndDate) {
            return DateTimeUtils.isThisDateEqualToThatDate(l.date, this.copyFromLessonEndDate) || DateTimeUtils.isThisDateBeforeThatDate(l.date, this.copyFromLessonEndDate);
          } else {
            return true;
          }
        }).map((l: any) => { return l.lessonId || 0 });
      }
      const targetStartDate = this.getAfterExistingLessonsDate();
      const request: any = {
        targetExtraLesson: '0',
        targetStartDate: targetStartDate,
        targetSubjectId: this.copyToClass.cId,
        verifyLinkAction: true,
        lessonId: lessonIds
      }
      this.linkTo(request).then((resp: any) => {
        CommonUtils.hideLoading();
        this.verifyLink(request, resp);
      });
    }
  }

  doLinkUnit() {
    const targetStartDate = (this.lessonCopyType === 'APPEND') ? this.getAfterExistingLessonsDate() : this.lessonInsertDate;
    const request = {
      linkAction: 0,
      subjectId: (this.copyFromClass) ? this.$refs.copyFromUnitlist.getSelectedUnit().subjectId : (this.copyFromBank.cId || this.copyFromBank.classId),
      targetExtraLesson: '0',
      targetStartDate: targetStartDate,
      targetSubjectId: this.copyToClass.cId,
      unitId: this.$refs.copyFromUnitlist.getSelectedUnitId(),
      verifyLinkAction: true,
      lessonId: this.$refs.copyFromUnitlist.getLessonIds()
    }
    this.linkTo(request).then((resp: any) => {
      this.verifyLink(request, resp);
    });
  }

  verifyLink(request: any, d: any) {
    CommonUtils.hideLoading();
    if (d.data.confirmLinkAction || (d.data.deletedLessons && d.data.deletedLessons.length > 0)) {
      this.$refs.confirm.confirm({
        title: this.$t('linkLessonLabel'),
        text: this.$t('linkLessonActions', { class: this.copyToClass.cN, date: request.targetStartDate }),
        option1ButtonAlternativeText: this.$t('shiftLabel'),
        option2ButtonAlternativeText: this.$t('overwriteLabel')
      }).then((result) => {
        if (result === 1) {
          this.finishLink(request, true);
        } else if (result === 2) {
          this.finishLink(request, false);
        } else {
          this.clearDragCopy();
        }
      });
    } else {
      this.reloadLessons();
    }
  }

  verifyLinkMany(request: any, d: any) {
    CommonUtils.hideLoading();
    if (d.data.confirmLinkAction) {
      this.$refs.confirm.confirm({
        title: this.$t('linkLessonLabel'),
        text: this.$t('linkLessonManyActions'),
        option1ButtonAlternativeText: this.$t('shiftLabel'),
        option2ButtonAlternativeText: this.$t('overwriteLabel')
      }).then((result) => {
        request.copyList = d.data.confirmRequests;
        request.verifyLinkAction = false;
        if (result === 1) {
          this.finishLinkMany(request, true);
        } else if (result === 2) {
          this.finishLinkMany(request, false);
        } else {
          this.clearDragCopy();
        }
      });
    } else {
      this.reloadLessons();
    }
  }

  finishLink(request: any, shift: boolean) {
    if (shift) {
      request.linkAction = 0;
    } else {
      request.linkAction = 2;
      request.verifyLinkAction = false;
    }

    this.bumpRequestWrapper(request, 'link');
  }

  finishLinkMany(request: any, shift: boolean) {
    if (shift) {
      request.linkAction = 0;
    } else {
      request.linkAction = 2;
      request.verifyLinkAction = false;
    }

    this.bumpRequestWrapperMany(request, 'link');
  }
}
